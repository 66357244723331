import React from 'react';
import PropTypes from "prop-types"
import axios from 'axios';


class SectionSeparator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: this.props.section.title || '',
      image:  this.props.image,
      sectionId: this.props.section.id,
      selectedFile: null,
      confirmationMessage: '',
      formId: Math.floor((1 + Math.random()) * 0x1000000).toString(16).substring(1),
      imageAlt: this.props.section.image_alt
    };


    this.fileInput = React.createRef();
    this.onFileChange = this.onFileChange.bind(this);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
  }

  componentWillUnmount(){
    window.removeEventListener('change', this.onFileChange, false);
    window.removeEventListener('change', this.handleInputChange, false);
    window.removeEventListener('submit', this.handleSubmit, false);
  }


  render () {
    return (
      <div className='card shadow mb-3' >
        <div className='card-header py-3 card-body-toggle'>
          <p className='text-primary m-0 font-weight-bold'>
          {this.state.title} - <small>Elemento separatore</small>
          <span className='ml-2'>
            <i className='fa fa-caret-down'></i>
          </span>
          <a className='btn btn-danger btn-sm float-right remove-section' data-id={this.state.sectionId} title='Elimina sezione'>
            <i className='fa fa-times'></i>
          </a>
        </p>
      </div>
      <div className='card-body card-body-closed'>
        <form onSubmit={this.handleSubmit} data-disable='false' id={this.state.formId}>
          <input type='hidden' value={this.props.section.page_id} name='page_id' id='section_page_id'/>
          <input type='hidden' value={this.props.type} name='type' id='section_type'/>

          <div className='form-row'>
            <div className='col-12'>
              <div className='form-group'>
                <label htmlFor='section_title'>Titolo</label>
                <input
                  className='form-control'
                  type='text'
                  value={this.state.title}
                  onChange={this.handleInputChange}
                  name='title'
                  id='section_title'
                  required />
              </div>
            </div>
          </div>
          <div className='form-row'>
            <div className='col-12 col-sm-4'>
	            <img className='mb-1 img-fluid' src={this.state.image} />
	            <div className='form-group file'>
                <label className='file optional' htmlFor='section_image'>Immagine</label>
                <input
                  className='form-control-file file optional'
                  type='file'
                  ref={this.fileInput}
                  onChange={this.onFileChange}
                  name='image'
                  accept=".jpg,.jpeg,.gif,.png"
                  id='section_image' />
              </div>
            </div>
            <div className='col-12 col-sm-4'>
              <div className='form-group'>
                <label htmlFor='section_image_alt'>Testo alternativo immagine</label>
                <input
                  className='form-control'
                  type='text'
                  value={this.state.imageAlt}
                  onChange={this.handleInputChange}
                  name='imageAlt'
                  id='section_image_alt' />
              </div>
            </div>
          </div>

          <div className='form-group'>
            <input type='submit' name='commit' value='Aggiorna Sezione' className='btn btn btn-primary' />
            <span className='save-confirm-message'>{this.state.confirmationMessage}</span>
          </div>
        </form>
      </div>
    </div>

    );
  }


  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }



  onFileChange(event) {
    const maxSize = 2097152;
    if (event.target.files[0].size > maxSize)
    {
      //File too big
      alert('File troppo grande: dimensione massima consentita 2MB');
      event.target.value = null;
    } else{
      // Update the state
      this.setState({ selectedFile: event.target.files[0] });
    }
  }

  afterSaveStateUpdate(record) {
    const s = record;
    this.setState({
      sectionId: s.id,
      title: s.title,
      image: s.image,
      imageAlt: s.image_alt,
    })


    this.setState({confirmationMessage: 'Sezione aggiornata'});
    setTimeout(() => {
      this.setState({confirmationMessage: ''})
    }, 3000);


  }

  handleSubmit(e){
    e.preventDefault();

    let formData = new FormData(document.getElementById(this.state.formId));
    // Set not standard fields
    formData.append("image_alt", this.state.imageAlt);
    if (this.state.selectedFile != null)
      formData.append("image", this.state.selectedFile, this.state.selectedFile.name);
    // Force format: json
    formData.append("format", 'json');


    if (this.state.sectionId == undefined){
      // CREATE NEW SECTION
      axios.post('/admin/sections/', formData,
      {
        headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        'Content-Type':  'application/json'
        }
      }).then(response => {
        this.afterSaveStateUpdate(response.data);
      })
    } else{
      // UPDATE SECTION
      axios.put('/admin/sections/'+this.state.sectionId, formData,
      {
        headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        'Content-Type':  'application/json'
        }
      }).then(response => {
        this.afterSaveStateUpdate(response.data);

      })
    }


  }
}



SectionSeparator.propTypes = {
  section: PropTypes.object,
  image: PropTypes.string,
  type: PropTypes.string
};
export default SectionSeparator
