import React from 'react';
import PropTypes from "prop-types"
import axios from 'axios';

class SectionArticleList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      listType: this.props.section.list_type || 'full',
      listPagination: this.props.section.list_pagination,
      sectionId: this.props.section.id,
      confirmationMessage: '',
      formId: Math.floor((1 + Math.random()) * 0x1000000).toString(16).substring(1),
      editorId: Math.floor((1 + Math.random()) * 0x1000000).toString(16).substring(1),
      selectDisabled: this.props.section.list_type == 'custom_selection' ? '' : 'disabled',
      selectClass: this.props.section.list_type == 'custom_selection' ? 'col-12' : 'col-12 d-none'
    };


    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  componentWillUnmount(){
    window.removeEventListener('change', this.handleInputChange, false);
    window.removeEventListener('submit', this.handleSubmit, false);
  }


  render () {
    const articles = this.props.articles.map((article) =>
      <option value={article[1]} selected={article[2]}>{article[0]}</option>
    )


    return (
      <div id="root">
        <div className='card shadow mb-3' >
          <div className='card-header py-3 card-body-toggle'>
            <p className='text-primary m-0 font-weight-bold'>
            Lista Articoli - <small>Configura opzioni</small>
            <span className='ml-2'>
              <i className='fa fa-caret-down'></i>
            </span>
            <a className='btn btn-danger btn-sm float-right remove-section' data-id={this.state.sectionId} title='Elimina sezione'>
              <i className='fa fa-times'></i>
            </a>
          </p>
        </div>
        <div className='card-body card-body-closed'>
          <form onSubmit={this.handleSubmit} data-disable='false' id={this.state.formId}>
            <input type='hidden' value={this.props.section.page_id} name='page_id' id='section_page_id'/>
            <input type='hidden' value={this.props.type} name='type' id='section_type'/>
            <div className='col-12'>

              <div className='form-group'>
                <label className='string optional' >Tipo di lista</label>
                <div className='radio-collection'>
                  <div className="radio">
                    <label>
                      <input
                        type="radio"
                        value="full"
                        name='listType'
                        checked={this.state.listType == "full"}
                        onChange={this.handleInputChange}
                      />
                      Completa
                    </label>
                  </div>
                  <div className="radio">
                    <label>
                      <input
                        type="radio"
                        value="limit_last"
                        name='listType'
                        checked={this.state.listType == "limit_last"}
                        onChange={this.handleInputChange}
                      />
                      I 6 articoli più recenti
                    </label>
                  </div>
                  <div className="radio">
                    <label>
                      <input
                        type="radio"
                        value="limit_random"
                        name='listType'
                        checked={this.state.listType == "limit_random"}
                        onChange={this.handleInputChange}
                      />
                      3 articoli casuali
                    </label>
                  </div>
                  <div className="radio">
                    <label>
                      <input
                        type="radio"
                        value="custom_selection"
                        name='listType'
                        checked={this.state.listType == "custom_selection"}
                        onChange={this.handleInputChange}
                      />
                      Selezione personalizzata
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12'>
              <div className='form-group'>
                <label htmlFor='section_list_pagination'>Paginazione</label>
                <input
                  className='form-control'
                  type='text'
                  value={this.state.listPagination}
                  onChange={this.handleInputChange}
                  name='listPagination'
                  id='section_list_pagination'
                  placeholder='Inserisci 0 se non vuoi che ci sia paginazione'
                  required />
              </div>

            </div>

            <div className={this.state.selectClass}>
              <div className='form-group'>
                <label htmlFor='section_article_ids'>Seleziona articoli</label>

                <select className="form-control" multiple="multiple" name="article_ids[]" id="section_article_ids" disabled={this.state.selectDisabled}>
                  {articles}

                </select>
              </div>
            </div>

            <div className='form-group'>
              <input type='submit' name='commit' value='Aggiorna Sezione' className='btn btn btn-primary' />
              <span className='save-confirm-message'>{this.state.confirmationMessage}</span>
            </div>
          </form>
        </div>
      </div>
    </div>
    );
  }



  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;


    if (name == 'listType'){
      if (value == 'custom_selection'){
        this.setState({ selectDisabled: ''})
        this.setState({ selectClass: 'col-12'})
      } else{
        this.setState({ selectDisabled: 'disabled'})
        this.setState({ selectClass: 'col-12 d-none'})
      }
    }


    this.setState({
      [name]: value
    });
  }

  afterSaveStateUpdate(record) {
    const s = record;

    this.setState({
      sectionId: s.id,
      listType: s.list_type,
      listPagination: s.list_pagination
    })

    this.setState({confirmationMessage: 'Sezione aggiornata'});
    setTimeout(() => {
      this.setState({confirmationMessage: ''})
    }, 3000);

  }



  handleSubmit(e){
    e.preventDefault();

    let formData = new FormData(document.getElementById(this.state.formId));
    // Set not standard fields
    formData.append("list_type", this.state.listType);
    formData.append("list_pagination", this.state.listPagination);

    // Force format: json
    formData.append("format", 'json');

    if (this.state.sectionId == undefined){
      // CREATE NEW SECTION
      axios.post('/admin/sections/', formData,
      {
        headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        'Content-Type':  'application/json'
        }
      }).then(response => {
        this.afterSaveStateUpdate(response.data);
      })
    } else{
      // UPDATE SECTION
      axios.put('/admin/sections/'+this.state.sectionId, formData,
      {
        headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        'Content-Type':  'application/json'
        }
      }).then(response => {
        this.afterSaveStateUpdate(response.data);

      })
    }


  }
}



SectionArticleList.propTypes = {
  section: PropTypes.object,
  type: PropTypes.string
};
export default SectionArticleList
