window.innerWidth<768&&$("[data-bss-disabled-mobile]").removeClass("animated").removeAttr("data-aos data-bss-hover-animate"),$(document).ready((function(){}));

submit_gdpr_log = function (event, page_url) {
  var xhttp = new XMLHttpRequest();
  xhttp.open("POST", "/gdpr_logs");
  xhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

  if (event['id']) {
    event['iubenda_id'] = event['id'];
    delete event['id'];
  }
  event['consent_page_url'] = page_url;

  xhttp.send("gdpr_log=" + encodeURIComponent(JSON.stringify(event)));

  if (xhttp.status !== 200) {
    console.error(xhtp.response.errors)
  }

};
