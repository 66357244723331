import React from 'react';
import PropTypes from "prop-types"
import axios from 'axios';


class SectionBulletList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sectionId: this.props.section.id,
      formId: Math.floor((1 + Math.random()) * 0x1000000).toString(16).substring(1),
      editorId: Math.floor((1 + Math.random()) * 0x1000000).toString(16).substring(1),
      confirmationMessage: '',
      title: this.props.section.title || '',
      description: this.props.section.description || '',
      bullet_title_0: this.props.section.bullet_title_0,
      bullet_title_1: this.props.section.bullet_title_1,
      bullet_title_2: this.props.section.bullet_title_2,
      bullet_title_3: this.props.section.bullet_title_3,
      bullet_title_4: this.props.section.bullet_title_4,
      bullet_title_5: this.props.section.bullet_title_5,
      bullet_description_0:  this.props.section.bullet_description_0,
      bullet_description_1:  this.props.section.bullet_description_1,
      bullet_description_2:  this.props.section.bullet_description_2,
      bullet_description_3:  this.props.section.bullet_description_3,
      bullet_description_4:  this.props.section.bullet_description_4,
      bullet_description_5:  this.props.section.bullet_description_5,
      bullet_image_0:  this.props.bullet_image_0 || 'https://via.placeholder.com/400x210',
      bullet_image_1:  this.props.bullet_image_1 || 'https://via.placeholder.com/400x210',
      bullet_image_2:  this.props.bullet_image_2 || 'https://via.placeholder.com/400x210',
      bullet_image_3:  this.props.bullet_image_3 || 'https://via.placeholder.com/400x210',
      bullet_image_4:  this.props.bullet_image_4 || 'https://via.placeholder.com/400x210',
      bullet_image_5:  this.props.bullet_image_5 || 'https://via.placeholder.com/400x210',
      selectedFiles: [null,  null, null, null, null, null]
    };



    this.fileInput = React.createRef();
    this.onFileChange = this.onFileChange.bind(this);

    this.handleInputChange = this.handleInputChange.bind(this);


    this.handleSubmit = this.handleSubmit.bind(this);

  }

  componentWillUnmount(){
    window.removeEventListener('change', this.onFileChange, false);
    window.removeEventListener('change', this.handleInputChange, false);
    window.removeEventListener('submit', this.handleSubmit, false);

  }


  render () {
    return (
      <div className='card shadow mb-3' >
        <div className='card-header py-3 card-body-toggle'>
          <p className='text-primary m-0 font-weight-bold'>
          {this.state.title} - <small>Sezione con punti</small>
          <span className='ml-2'>
            <i className='fa fa-caret-down'></i>
          </span>
          <a className='btn btn-danger btn-sm float-right remove-section' data-id={this.state.sectionId} title='Elimina sezione'>
            <i className='fa fa-times'></i>
          </a>
        </p>
      </div>
      <div className='card-body card-body-closed'>
        <form onSubmit={this.handleSubmit} data-disable='false' id={this.state.formId}>
          <input type='hidden' value={this.props.section.page_id} name='page_id' id='section_page_id'/>
          <input type='hidden' value={this.props.type} name='type' id='section_type'/>

          <div className='form-row'>
            <div className='col-12'>
              <div className='form-group'>
                <label htmlFor='section_title'>Titolo</label>
                <input
                  className='form-control'
                  type='text'
                  value={this.state.title}
                  onChange={this.handleInputChange}
                  name='title'
                  id='section_title'
                  required />
              </div>
            </div>
          </div>

          <div className='form-row'>
            <div className='col-12'>
              <div className='form-group '>
                <label className='string optional' htmlFor='section_description'>Testo</label>
                <textarea
                  className='form-control rich_text_editor'
                  type='text'
                  value={this.state.description}
                  onChange={this.handleInputChange}
                  name='description'
                  id={this.state.editorId} />
              </div>
            </div>
          </div>

          <div className='form-row'>
            <div className='col-12 col-sm-6'>
	            <img className='mb-1 img-fluid' src={this.state.bullet_image_0} />
	            <div className='form-group file'>
                <label className='file optional' htmlFor='section_bullet_image_0'>Immagine</label>
                <input
                  className='form-control-file file optional'
                  type='file'
                  ref={this.fileInput}
                  onChange={this.onFileChange}
                  name='bullet_image_0'
                  accept=".jpg,.jpeg,.gif,.png"
                  id='section_bullet_image_0' />
              </div>

              <div className='form-group'>
                <label htmlFor='section_bullet_title_0'>Titolo</label>
                <input
                  className='form-control'
                  type='text'
                  value={this.state.bullet_title_0}
                  onChange={this.handleInputChange}
                  name='bullet_title_0'
                  id='section_bullet_title_0' />
              </div>

              <div className='form-group'>
                <label className='string optional' htmlFor='section_bullet_description_0'>Testo</label>
                <textarea
                  className='form-control'
                  type='text'
                  value={this.state.bullet_description_0}
                  onChange={this.handleInputChange}
                  name='bullet_description_0'/>
              </div>
            </div>

            <div className='col-12 col-sm-6'>
              <img className='mb-1 img-fluid' src={this.state.bullet_image_1} />
	            <div className='form-group file'>
                <label className='file optional' htmlFor='section_bullet_image_1'>Immagine</label>
                <input
                  className='form-control-file file optional'
                  type='file'
                  ref={this.fileInput}
                  onChange={this.onFileChange}
                  name='bullet_image_1'
                  accept=".jpg,.jpeg,.gif,.png"
                  id='section_bullet_image_1' />
              </div>

              <div className='form-group'>
                <label htmlFor='section_bullet_title_1'>Titolo</label>
                <input
                  className='form-control'
                  type='text'
                  value={this.state.bullet_title_1}
                  onChange={this.handleInputChange}
                  name='bullet_title_1'
                  id='section_bullet_title_1' />
              </div>
              <div className='form-group'>
                <label className='string optional' htmlFor='section_bullet_description_1'>Testo</label>
                <textarea
                  className='form-control'
                  type='text'
                  value={this.state.bullet_description_1}
                  onChange={this.handleInputChange}
                  name='bullet_description_1'/>
              </div>
            </div>

            <div className='col-12 col-sm-6'>
              <img className='mb-1 img-fluid' src={this.state.bullet_image_2} />
	            <div className='form-group file'>
                <label className='file optional' htmlFor='section_bullet_image_2'>Immagine</label>
                <input
                  className='form-control-file file optional'
                  type='file'
                  ref={this.fileInput}
                  onChange={this.onFileChange}
                  name='bullet_image_2'
                  accept=".jpg,.jpeg,.gif,.png"
                  id='section_bullet_image_2' />
              </div>

              <div className='form-group'>
                <label htmlFor='section_bullet_title_2'>Titolo</label>
                <input
                  className='form-control'
                  type='text'
                  value={this.state.bullet_title_2}
                  onChange={this.handleInputChange}
                  name='bullet_title_2'
                  id='section_bullet_title_2'/>
              </div>
              <div className='form-group'>
                <label className='string optional' htmlFor='section_bullet_description_2'>Testo</label>
                <textarea
                  className='form-control'
                  type='text'
                  value={this.state.bullet_description_2}
                  onChange={this.handleInputChange}
                  name='bullet_description_2'/>
              </div>
            </div>

            <div className='col-12 col-sm-6'>
              <img className='mb-1 img-fluid' src={this.state.bullet_image_3} />
	            <div className='form-group file'>
                <label className='file optional' htmlFor='section_bullet_image_3'>Immagine</label>
                <input
                  className='form-control-file file optional'
                  type='file'
                  ref={this.fileInput}
                  onChange={this.onFileChange}
                  name='bullet_image_3'
                  accept=".jpg,.jpeg,.gif,.png"
                  id='section_bullet_image_3' />
              </div>

              <div className='form-group'>
                <label htmlFor='section_bullet_title_3'>Titolo</label>
                <input
                  className='form-control'
                  type='text'
                  value={this.state.bullet_title_3}
                  onChange={this.handleInputChange}
                  name='bullet_title_3'
                  id='section_bullet_title_3'/>
              </div>
              <div className='form-group'>
                <label className='string optional' htmlFor='section_bullet_description_3'>Testo</label>
                <textarea
                  className='form-control'
                  type='text'
                  value={this.state.bullet_description_3}
                  onChange={this.handleInputChange}
                  name='bullet_description_3'/>
              </div>
            </div>

            <div className='col-12 col-sm-6'>
              <img className='mb-1 img-fluid' src={this.state.bullet_image_4} />
	            <div className='form-group file'>
                <label className='file optional' htmlFor='section_bullet_image_4'>Immagine</label>
                <input
                  className='form-control-file file optional'
                  type='file'
                  ref={this.fileInput}
                  onChange={this.onFileChange}
                  name='bullet_image_4'
                  accept=".jpg,.jpeg,.gif,.png"
                  id='section_bullet_image_4' />
              </div>

              <div className='form-group'>
                <label htmlFor='section_bullet_title_4'>Titolo</label>
                <input
                  className='form-control'
                  type='text'
                  value={this.state.bullet_title_4}
                  onChange={this.handleInputChange}
                  name='bullet_title_4'
                  id='section_bullet_title_4'/>
              </div>
              <div className='form-group'>
                <label className='string optional' htmlFor='section_bullet_description_4'>Testo</label>
                <textarea
                  className='form-control'
                  type='text'
                  value={this.state.bullet_description_4}
                  onChange={this.handleInputChange}
                  name='bullet_description_4'/>
              </div>
            </div>

            <div className='col-12 col-sm-6'>
              <img className='mb-1 img-fluid' src={this.state.bullet_image_5} />
	            <div className='form-group file'>
                <label className='file optional' htmlFor='section_bullet_image_5'>Immagine</label>
                <input
                  className='form-control-file file optional'
                  type='file'
                  ref={this.fileInput}
                  onChange={this.onFileChange}
                  name='bullet_image_5'
                  accept=".jpg,.jpeg,.gif,.png"
                  id='section_bullet_image_5' />
              </div>

              <div className='form-group'>
                <label htmlFor='section_bullet_title_5'>Titolo</label>
                <input
                  className='form-control'
                  type='text'
                  value={this.state.bullet_title_5}
                  onChange={this.handleInputChange}
                  name='bullet_title_5'
                  id='section_bullet_title_5'/>
              </div>
              <div className='form-group'>
                <label className='string optional' htmlFor='section_bullet_description_5'>Testo</label>
                <textarea
                  className='form-control'
                  type='text'
                  value={this.state.bullet_description_5}
                  onChange={this.handleInputChange}
                  name='bullet_description_5'/>
              </div>
            </div>

          </div>



          <div className='form-group'>
            <input type='submit' name='commit' value='Aggiorna Sezione' className='btn btn btn-primary' />
            <span className='save-confirm-message'>{this.state.confirmationMessage}</span>
          </div>
        </form>
      </div>
    </div>

    );
  }


  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }


  onFileChange(event) {
    const maxSize = 2097152;
    let fileList = this.state.selectedFiles;

    let id = event.target.id.split('_')[3];
    if (event.target.files[0].size > maxSize)
    {
      //File too big
      alert('File troppo grande: dimensione massima consentita 2MB');
      event.target.value = null;
    } else{
      // Update the state
      fileList[id] = event.target.files[0];
      this.setState({ selectedFiles: fileList });
    }
  }

  afterSaveStateUpdate(record) {
    const s = record;
    this.setState({
      sectionId: s.id,
      title: s.title,
      description: s.description,
      bullet_title_0: s.bullet_title_0,
      bullet_title_1: s.bullet_title_1,
      bullet_title_2: s.bullet_title_2,
      bullet_title_3: s.bullet_title_3,
      bullet_title_4: s.bullet_title_4,
      bullet_title_5: s.bullet_title_5,
      bullet_description_0:  s.bullet_description_0,
      bullet_description_1:  s.bullet_description_1,
      bullet_description_2:  s.bullet_description_2,
      bullet_description_3:  s.bullet_description_3,
      bullet_description_4:  s.bullet_description_4,
      bullet_description_5:  s.bullet_description_5,
      bullet_image_0:  s.bullet_image_0,
      bullet_image_1:  s.bullet_image_1,
      bullet_image_2:  s.bullet_image_2,
      bullet_image_3:  s.bullet_image_3,
      bullet_image_4:  s.bullet_image_4,
      bullet_image_5:  s.bullet_image_5,

    })


    this.setState({confirmationMessage: 'Sezione aggiornata'});
    setTimeout(() => {
      this.setState({confirmationMessage: ''})
    }, 3000);


  }

  handleSubmit(e){
    e.preventDefault();

    let formData = new FormData(document.getElementById(this.state.formId));
    // Set not standard fields

    for (let i = 0; i < this.state.selectedFiles.length; i++) {
      if (this.state.selectedFiles[i] != null)
        formData.append('bullet_image_' + i, this.state.selectedFiles[i], this.state.selectedFiles[i].name);
    }


    // Force format: json
    formData.append("format", 'json');


    if (this.state.sectionId == undefined){
      // CREATE NEW SECTION
      axios.post('/admin/sections/', formData,
      {
        headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        'Content-Type':  'application/json'
        }
      }).then(response => {
        this.afterSaveStateUpdate(response.data);
      })
    } else{
      // UPDATE SECTION
      axios.put('/admin/sections/'+this.state.sectionId, formData,
      {
        headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        'Content-Type':  'application/json'
        }
      }).then(response => {
        this.afterSaveStateUpdate(response.data);

      })
    }


  }
}



SectionBulletList.propTypes = {
  section: PropTypes.object,
  type: PropTypes.string
};
export default SectionBulletList
