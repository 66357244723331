import React from 'react';
import PropTypes from "prop-types"
import axios from 'axios';


class SectionContactForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sectionId: this.props.section.id,
      formId: Math.floor((1 + Math.random()) * 0x1000000).toString(16).substring(1),
      editorId: Math.floor((1 + Math.random()) * 0x1000000).toString(16).substring(1),
      confirmationMessage: '',
      title: this.props.section.title || '',
      description: this.props.section.description || '',
      contactFields: this.props.contact_fields || [],
      contactRequiredFields:  this.props.contact_required_fields || []
    };



    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.appendToContactFields = this.appendToContactFields.bind(this);
    this.appendToContactRequiredFields = this.appendToContactRequiredFields.bind(this);


  }

  componentWillUnmount(){
    window.removeEventListener('change', this.handleInputChange, false);
    window.removeEventListener('submit', this.handleSubmit, false);
    window.removeEventListener('change', this.appendToContactFields, false);
    window.removeEventListener('change', this.appendToContactRequiredFields, false);
  }


  render () {
    return (
      <div className='card shadow mb-3' >
        <div className='card-header py-3 card-body-toggle'>
          <p className='text-primary m-0 font-weight-bold'>
          {this.state.title} - <small>Form di contatto</small>
          <span className='ml-2'>
            <i className='fa fa-caret-down'></i>
          </span>
          <a className='btn btn-danger btn-sm float-right remove-section' data-id={this.state.sectionId} title='Elimina sezione'>
            <i className='fa fa-times'></i>
          </a>
        </p>
      </div>
      <div className='card-body card-body-closed'>
        <form onSubmit={this.handleSubmit} data-disable='false' id={this.state.formId}>
          <input type='hidden' value={this.props.section.page_id} name='page_id' id='section_page_id'/>
          <input type='hidden' value={this.props.type} name='type' id='section_type'/>

          <div className='form-row'>
            <div className='col-12'>
              <div className='form-group'>
                <label htmlFor='section_title'>Titolo</label>
                <input
                  className='form-control'
                  type='text'
                  value={this.state.title}
                  onChange={this.handleInputChange}
                  name='title'
                  id='section_title'
                  required />
              </div>
            </div>
          </div>

          <div className='form-row'>
            <div className='col-12'>
              <div className='form-group '>
                <label className='string optional' htmlFor='section_description'>Testo</label>
                <textarea
                  className='form-control rich_text_editor'
                  type='text'
                  value={this.state.description}
                  onChange={this.handleInputChange}
                  name='description'
                  id={this.state.editorId}
                  required />
              </div>
            </div>
          </div>

          <div className='form-row'>
            <div className='col-12'>
              <h5>Campi richiesti</h5>
              <hr/>
            </div>
          </div>

          <div className='form-row'>
            <div className='col-12 col-sm-4 mb-4'>
              <div className="custom-control custom-switch">

                <h2>{this.state.contactFields.indexOf("company_name") != -1}</h2>
                <input name="company_name" type="hidden" value="0" />
                <input className="custom-control-input boolean optional" type="checkbox" value="1" name="company_name" id="company_name"
                onChange={this.appendToContactFields}
                defaultChecked={this.state.contactFields.indexOf("company_name") != -1}/>
                <label className="custom-control-label boolean optional" htmlFor="company_name">
                  Nome Azienda
                </label>
              </div>
            </div>

            <div className='col-12 col-sm-4 mb-4'>
              <div className="custom-control custom-switch">
                <input name="company_name_required" type="hidden" value="0" />
                <input className="custom-control-input boolean optional" type="checkbox" value="1" name="company_name" id="company_name_required"
                onChange={this.appendToContactRequiredFields}
                defaultChecked={this.state.contactRequiredFields.indexOf("company_name") != -1}/>
                <label className="custom-control-label boolean optional" htmlFor="company_name_required">
                  Obbligatorio
                </label>
              </div>
            </div>
          </div>

          <div className='form-row'>
            <div className='col-12 col-sm-4 mb-4'>

              <div className="custom-control custom-switch">
                <input name="email" type="hidden" value="0" />
                <input className="custom-control-input boolean optional" type="checkbox" value="1" name="email" id="email"
                onChange={this.appendToContactFields}
                defaultChecked={this.state.contactFields.indexOf("email") != -1}/>
                <label className="custom-control-label boolean optional" htmlFor="email">
                  Email
                </label>
              </div>
	          </div>
            <div className='col-12 col-sm-4 mb-4'>
              <div className="custom-control custom-switch">
                <input name="email_required" type="hidden" value="0" />
                <input className="custom-control-input boolean optional" type="checkbox" value="1" name="email" id="email_required"
                onChange={this.appendToContactRequiredFields}
                defaultChecked={this.state.contactRequiredFields.indexOf("email") != -1}/>
                <label className="custom-control-label boolean optional" htmlFor="email_required">
                  Obbligatorio
                </label>
              </div>
            </div>
          </div>

          <div className='form-row'>
            <div className='col-12 col-sm-4 mb-4'>
              <div className="custom-control custom-switch">
                <input name="first_name" type="hidden" value="0" />
                <input className="custom-control-input boolean optional" type="checkbox" value="1" name="first_name" id="first_name"
                onChange={this.appendToContactFields}
                defaultChecked={this.state.contactFields.indexOf("first_name") != -1}/>
                <label className="custom-control-label boolean optional" htmlFor="first_name">
                  Nome
                </label>
              </div>
	          </div>
            <div className='col-12 col-sm-4 mb-4'>
              <div className="custom-control custom-switch">
                <input name="first_name_required" type="hidden" value="0" />
                <input className="custom-control-input boolean optional" type="checkbox" value="1" name="first_name" id="first_name_required"
                onChange={this.appendToContactRequiredFields}
                defaultChecked={this.state.contactRequiredFields.indexOf("first_name") != -1}/>
                <label className="custom-control-label boolean optional" htmlFor="first_name_required">
                  Obbligatorio
                </label>
              </div>
            </div>
          </div>

          <div className='form-row'>
            <div className='col-12 col-sm-4 mb-4'>
              <div className="custom-control custom-switch">
                <input name="last_name" type="hidden" value="0" />
                <input className="custom-control-input boolean optional" type="checkbox" value="1" name="last_name" id="last_name"
                onChange={this.appendToContactFields}
                defaultChecked={this.state.contactFields.indexOf("last_name") != -1}/>
                <label className="custom-control-label boolean optional" htmlFor="last_name">
                  Cognome
                </label>
              </div>
	          </div>
            <div className='col-12 col-sm-4 mb-4'>
              <div className="custom-control custom-switch">
                <input name="last_name_required" type="hidden" value="0" />
                <input className="custom-control-input boolean optional" type="checkbox" value="1" name="last_name" id="last_name_required"
                onChange={this.appendToContactRequiredFields}
                defaultChecked={this.state.contactRequiredFields.indexOf("last_name") != -1}/>
                <label className="custom-control-label boolean optional" htmlFor="last_name_required">
                  Obbligatorio
                </label>
              </div>
            </div>
          </div>

          <div className='form-row'>
            <div className='col-12 col-sm-4 mb-4'>
              <div className="custom-control custom-switch">
                <input name="phone" type="hidden" value="0" />
                <input className="custom-control-input boolean optional" type="checkbox" value="1" name="phone" id="phone"
                onChange={this.appendToContactFields}
                defaultChecked={this.state.contactFields.indexOf("phone") != -1}/>
                <label className="custom-control-label boolean optional" htmlFor="phone">
                  Telefono
                </label>
              </div>
	          </div>
            <div className='col-12 col-sm-4 mb-4'>
              <div className="custom-control custom-switch">
                <input name="phone_required" type="hidden" value="0" />
                <input className="custom-control-input boolean optional" type="checkbox" value="1" name="phone" id="phone_required"
                onChange={this.appendToContactRequiredFields}
                defaultChecked={this.state.contactRequiredFields.indexOf("phone") != -1}/>
                <label className="custom-control-label boolean optional" htmlFor="phone_required">
                  Obbligatorio
                </label>
              </div>
            </div>
          </div>

          <div className='form-row'>
            <div className='col-12 col-sm-4 mb-4'>
              <div className="custom-control custom-switch">
                <input name="subject" type="hidden" value="0" />
                <input className="custom-control-input boolean optional" type="checkbox" value="1" name="subject" id="subject"
                onChange={this.appendToContactFields}
                defaultChecked={this.state.contactFields.indexOf("subject") != -1}/>
                <label className="custom-control-label boolean optional" htmlFor="subject">
                  Oggetto
                </label>
              </div>
	          </div>
            <div className='col-12 col-sm-4 mb-4'>
              <div className="custom-control custom-switch">
                <input name="subject_required" type="hidden" value="0" />
                <input className="custom-control-input boolean optional" type="checkbox" value="1" name="subject" id="subject_required"
                onChange={this.appendToContactRequiredFields}
                defaultChecked={this.state.contactRequiredFields.indexOf("subject") != -1}/>
                <label className="custom-control-label boolean optional" htmlFor="subject_required">
                  Obbligatorio
                </label>
              </div>
            </div>
          </div>

          <div className='form-row'>
            <div className='col-12 col-sm-4 mb-4'>
              <div className="custom-control custom-switch">
                <input name="message" type="hidden" value="0" />
                <input className="custom-control-input boolean optional" type="checkbox" value="1" name="message" id="message"
                onChange={this.appendToContactFields}
                defaultChecked={this.state.contactFields.indexOf("message") != -1}/>
                <label className="custom-control-label boolean optional" htmlFor="message">
                  Messaggio
                </label>
              </div>
	          </div>
            <div className='col-12 col-sm-4 mb-4'>
              <div className="custom-control custom-switch">
                <input name="message_required" type="hidden" value="0" />
                <input className="custom-control-input boolean optional" type="checkbox" value="1" name="message" id="message_required"
                onChange={this.appendToContactRequiredFields}
                defaultChecked={this.state.contactRequiredFields.indexOf("message") != -1}/>
                <label className="custom-control-label boolean optional" htmlFor="message_required">
                  Obbligatorio
                </label>
              </div>
            </div>
          </div>

          <div className='form-group'>
            <input type='submit' name='commit' value='Aggiorna Sezione' className='btn btn btn-primary' />
            <span className='save-confirm-message'>{this.state.confirmationMessage}</span>
          </div>
        </form>
      </div>
    </div>

    );
  }


  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }


  appendToContactFields(event){
    const target = event.target;
    const value = target.checked;
    const name = target.name;
    let fields = this.state.contactFields;
    if (target.checked){
      fields.push(name);
    } else {
      var index = fields.indexOf(name)
      if (index !== -1) {
        fields.splice(index, 1);
      }
    }
    this.setState({
      ['contactFields']: fields
    })
  }


  appendToContactRequiredFields(event){
    const target = event.target;
    const value = target.checked;
    const name = target.name;
    let fields = this.state.contactRequiredFields;
    if (target.checked){
      fields.push(name);
    } else {
      var index = fields.indexOf(name)
      if (index !== -1) {
        fields.splice(index, 1);
      }
    }
    this.setState({
      ['contactRequiredFields']: fields
    })
  }



  onFileChange(event) {
    const maxSize = 2097152;
    if (event.target.files[0].size > maxSize)
    {
      //File too big
      alert('File troppo grande: dimensione massima consentita 2MB');
      event.target.value = null;
    } else{
      // Update the state
      this.setState({ selectedFile: event.target.files[0] });
    }
  }

  afterSaveStateUpdate(record) {
    const s = record;
    this.setState({
      sectionId: s.id,
      title: s.title,
      description: s.description,
      contactFields: s.contact_fields || [],
      contactRequiredFields: s.contact_required_fields  || []
    })


    this.setState({confirmationMessage: 'Sezione aggiornata'});
    setTimeout(() => {
      this.setState({confirmationMessage: ''})
    }, 3000);


  }

  handleSubmit(e){
    e.preventDefault();

    let formData = new FormData(document.getElementById(this.state.formId));
    // Set not standard fields
    formData.append("contact_fields", this.state.contactFields);
    formData.append("contact_required_fields", this.state.contactRequiredFields);

    // Force format: json
    formData.append("format", 'json');


    if (this.state.sectionId == undefined){
      // CREATE NEW SECTION
      axios.post('/admin/sections/', formData,
      {
        headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        'Content-Type':  'application/json'
        }
      }).then(response => {
        this.afterSaveStateUpdate(response.data);
      })
    } else{
      // UPDATE SECTION
      axios.put('/admin/sections/'+this.state.sectionId, formData,
      {
        headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        'Content-Type':  'application/json'
        }
      }).then(response => {
        this.afterSaveStateUpdate(response.data);

      })
    }


  }
}



SectionContactForm.propTypes = {
  section: PropTypes.object,
  type: PropTypes.string
};
export default SectionContactForm
