import React from 'react';
import PropTypes from "prop-types"
import axios from 'axios';



class SectionCustomCode extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: this.props.section.title || '',
      description: this.props.section.description || '',
      sectionId: this.props.section.id,
      confirmationMessage: '',
      formId: Math.floor((1 + Math.random()) * 0x1000000).toString(16).substring(1),
      editorId: Math.floor((1 + Math.random()) * 0x1000000).toString(16).substring(1)
    };


    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  componentWillUnmount(){
    window.removeEventListener('change', this.handleInputChange, false);
    window.removeEventListener('submit', this.handleSubmit, false);
  }


  render () {
    return (
      <div id="root">
        <div className='card shadow mb-3' >
          <div className='card-header py-3 card-body-toggle'>
            <p className='text-primary m-0 font-weight-bold'>
            {this.state.title} - <small>Codice Libero</small>
            <span className='ml-2'>
              <i className='fa fa-caret-down'></i>
            </span>
            <a className='btn btn-danger btn-sm float-right remove-section' data-id={this.state.sectionId} title='Elimina sezione'>
              <i className='fa fa-times'></i>
            </a>
          </p>
        </div>
        <div className='card-body card-body-closed'>
          <form onSubmit={this.handleSubmit} data-disable='false' id={this.state.formId}>
            <input type='hidden' value={this.props.section.page_id} name='page_id' id='section_page_id'/>
            <input type='hidden' value={this.props.type} name='type' id='section_type'/>

            <div className='form-row'>
              <div className='col-12 col-sm-7'>
                <div className='form-group'>
                  <label htmlFor='section_title'>Titolo</label>
                  <input
                    className='form-control'
                    type='text'
                    value={this.state.title}
                    onChange={this.handleInputChange}
                    name='title'
                    id='section_title'
                    required />
                </div>
              </div>
            </div>

            <div className='form-row'>
              <div className='col-12'>
                <div className='form-group '>
                  <label className='string optional' htmlFor='section_description'>Codice HTML/JS</label>
                  <textarea
                    className='form-control'
                    type='text'
                    rows='6'
                    value={this.state.description}
                    onChange={this.handleInputChange}
                    name='description'
                    id={this.state.editorId}
                    required />
                </div>
              </div>
            </div>
            <div className='form-group'>
              <input type='submit' name='commit' value='Aggiorna Sezione' className='btn btn btn-primary' />
              <span className='save-confirm-message'>{this.state.confirmationMessage}</span>
            </div>
          </form>
        </div>
      </div>
    </div>
    );
  }


  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  afterSaveStateUpdate(record) {
    const s = record;
    this.setState({
      sectionId: s.id,
      title: s.title,
      description: s.description
    })

    this.setState({confirmationMessage: 'Sezione aggiornata'});
    setTimeout(() => {
      this.setState({confirmationMessage: ''})
    }, 3000);

  }



  handleSubmit(e){
    e.preventDefault();

    let formData = new FormData(document.getElementById(this.state.formId));
    // Set not standard fields


    // Force format: json
    formData.append("format", 'json');

    if (this.state.sectionId == undefined){
      // CREATE NEW SECTION
      axios.post('/admin/sections/', formData,
      {
        headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        'Content-Type':  'application/json'
        }
      }).then(response => {
        this.afterSaveStateUpdate(response.data);
      })
    } else{
      // UPDATE SECTION
      axios.put('/admin/sections/'+this.state.sectionId, formData,
      {
        headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        'Content-Type':  'application/json'
        }
      }).then(response => {
        this.afterSaveStateUpdate(response.data);

      })
    }


  }
}



SectionCustomCode.propTypes = {
  section: PropTypes.object,
  type: PropTypes.string
};
export default SectionCustomCode
